<template>
  <div class="page">
    <app-header :navIndex="4"></app-header>
    <banner :data="banner"></banner>
    <!--中国领先的葡萄酒知识传授机构-->
    <section class="top-box section-container mt-md-12 pt-md-12 mb-md-12">
      <div class="top-border hidden-md-and-up white"></div>
      <section-title :title="institution.title" :enTitle="institution.enTitle"></section-title>
      <div class="desc pa-5 pa-md-0 mt-md-2 mb-md-12">{{ institution.desc }}</div>
      <div class="d-flex flex-column flex-md-row justify-md-space-between mt-md-12" v-if="false">
        <div class="col-12 col-md-4 img-box pa-0">
          <v-img :src="institution.img.src"></v-img>
          <div class="text-box">
            <div class="white--text text-center text-title">{{ institution.img.title }}</div>
            <div class="white--text text-center text-subTitle">{{ institution.img.subTitle }}</div>
          </div>
        </div>
        <div class="col-md-8 inner-box ml-md-5">
          <div class="text-size" v-for="(item,index) in institution.items" :key="index">
            {{ item.numTitle + '：' }}
            <router-link to="">{{ item.title }}</router-link>
          </div>
        </div>
      </div>
    </section>
    <!--团队-->
    <section class="section-container mt-12 pt-md-12">
      <section-title :title="team.title" :enTitle="team.enTitle"></section-title>
      <div class="d-flex flex-md-row align-center justify-center">
        <div class="type ma-4 ma-md-12 mt-md-5 pb-1" v-for="(item,index) in team.typeList"
             :class="item.id==activeIndex?'selected-type':''" @click="selectType(item)">{{ item.city }}
        </div>
      </div>
      <div class="swiper-box">
        <swiper class="pb-2 pt-2" ref="swiper" :options="$vuetify.breakpoint.mdAndUp?swiperOpts:swiperOptsSm">
          <swiper-slide class="item-swiper" v-for="(item,index) in team.items"
                        :key="index">
            <div class="item-box text-center">
              <img class="item-img mt-5 mt-md-0" :src="$utils.formatImgUrl(item.imgUrl)"/>
            </div>
          </swiper-slide>
        </swiper>
        <swiper class="pb-2 pt-2" ref="swiper" :options="$vuetify.breakpoint.mdAndUp?swiperOpts:swiperOptsSm">
          <swiper-slide class="item-swiper" v-for="(item,index) in team.items"
                        :key="index">
            <div class="item-box text-center">
              <div class="text-center item-title mt-4">{{ item.title }}</div>
              <div class="hidden-md-and-up item-line d-inline-flex mb-2 mb-md-0"></div>
              <div class="desc desc-box pa-3 mt-md-n3">{{ item.desc }}</div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <!--wset-->
    <section class="section-container mt-12 pt-md-12 mb-md-12">
      <section-title :title="wset.title" :enTitle="wset.enTitle"></section-title>
      <div class="desc mt-md-2 mb-md-12 pa-5 pa-md-0" v-html="wset.desc"></div>
      <div class="d-flex flex-md-row justify-md-space-between mt-12">
        <div class="col-md-4 pa-0">
          <div class="img-box">
            <v-img :src="wset.img.src"></v-img>
            <div class="text-box">
              <div class="white--text text-center text-title">{{ wset.img.title }}</div>
              <div class="white--text text-center text-subTitle">{{ wset.img.subTitle }}</div>
            </div>
          </div>
          <div class="wset-title-box hidden-md-and-up mt-n5 d-flex flex-wrap pl-7 pr-7 justify-space-between">
            <div class="wset-title text-center white--text" v-for="(item,index) in wset.items" :key="index"
                 @click="enter(item)">
              {{ item.subTitle }}
            </div>
          </div>
        </div>
        <div class="wset-title-box1 col-md-8 ml-md-5 d-flex flex-column">
          <div class="text-size" v-for="(item,index) in wset.items" :key="index">
            {{ item.title + '：' }}
            <router-link :to="{path:'/services/preview',query:{id:item.id}}">
              {{ item.subTitle }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
    <!--        <section class="section-container mt-12 pt-md-12 mb-md-12">-->
    <!--            <section-title :title="onlineCourse.title" :enTitle="onlineCourse.enTitle"></section-title>-->
    <!--            <div class="course-box mt-5 d-flex flex-wrap">-->
    <!--                <v-hover class="item-box mb-4 mb-md-0" v-slot:default="{hover}" v-for="(item,i) in onlineCourse.items"-->
    <!--                         :key="i">-->
    <!--                    <v-card :elevation="hover ? 6 : 0"-->
    <!--                            :class="{ 'on-hover': hover }">-->
    <!--                        <v-img contain :src="$utils.formatImgUrl(item)"></v-img>-->
    <!--                    </v-card>-->
    <!--                </v-hover>-->
    <!--            </div>-->
    <!--        </section>-->
    <!--        &lt;!&ndash;葡萄酒认证课程&ndash;&gt;-->
    <!--        <section class="section-container mt-12 pt-md-12 mb-md-12" v-if="false">-->
    <!--            <section-title :title="course.title" :enTitle="course.enTitle"></section-title>-->
    <!--            <div class="course-box mt-5 d-flex flex-wrap">-->
    <!--                <v-hover class="item-hidden-sm item-box pb-md-5" v-slot:default="{hover}"-->
    <!--                         v-for="(item,index) in course.items"-->
    <!--                         :key="index">-->
    <!--                    <v-card :elevation="hover ? 6 : 0"-->
    <!--                            :class="{ 'on-hover': hover }">-->
    <!--                        <v-img :src="item.img"></v-img>-->
    <!--                        <div class="text-center mt-md-5 item-title">{{item.title}}</div>-->
    <!--                        <div class="desc pa-md-3" v-html="item.desc"></div>-->
    <!--                        <div class="pa-md-3 pt-md-0" v-if="item.content">-->
    <!--                            {{$i18n.t('common.contents')}}：：-->
    <!--                            <div class="item-content">-->
    <!--                                <div class="desc" v-for="(title,i) in item.content">{{'·' + title}}</div>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </v-card>-->
    <!--                </v-hover>-->
    <!--                <swiper class="pb-2 hidden-md-and-up" ref="swiper" :options="swiperOptsSm">-->
    <!--                    <swiper-slide class="item-swiper" v-for="(item,index) in course.items"-->
    <!--                                  :key="index">-->
    <!--                        <div class="item-box text-center">-->
    <!--                            <v-img :src="item.img"></v-img>-->
    <!--                            <div class="text-center mt-4 item-title">{{item.title}}</div>-->
    <!--                            <div class="item-line d-inline-flex mb-2"></div>-->
    <!--                            <div class="desc pa-3" v-html="item.desc"></div>-->
    <!--                            <div class="pa-3 pt-md-0 text-left" v-if="item.content">-->
    <!--                                {{$i18n.t('common.contents')}}：-->
    <!--                                <div class="item-content">-->
    <!--                                    <div class="desc" v-for="(title,i) in item.content">{{'·' + title}}</div>-->
    <!--                                </div>-->
    <!--                            </div>-->
    <!--                        </div>-->
    <!--                    </swiper-slide>-->
    <!--                </swiper>-->
    <!--            </div>-->
    <!--        </section>-->
    <!--        <section class="section-container mt-12 pt-md-12 mb-md-12" v-if="false" v-for="(item,index) in list"-->
    <!--                 :key="index"-->
    <!--                 :class="index==list.length-1?'mb-12':''">-->
    <!--            <section-title :title="item.title" :enTitle="item.enTitle"></section-title>-->
    <!--            <div class="list-box mt-md-5 d-flex flex-wrap">-->
    <!--                <v-hover class="item-box pb-md-5 mt-6 mt-md-0" v-slot:default="{hover}" v-for="(item1,i) in item.items"-->
    <!--                         :key="i">-->
    <!--                    <v-card :elevation="hover ? 6 : 0"-->
    <!--                            :class="{ 'on-hover': hover }">-->
    <!--                        <v-img :src="item1.img"></v-img>-->
    <!--                        <div class="text-center mt-5 item-title pl-2 pr-2">{{item1.title}}</div>-->
    <!--                        <div class="desc pa-3" v-html="item1.desc"></div>-->
    <!--                    </v-card>-->
    <!--                </v-hover>-->
    <!--            </div>-->
    <!--        </section>-->
  </div>
</template>
<script>
import Banner from "../../components/base/banner";
import SectionTitle from "../company/components/section-title";
import AppHeader from "../../components/core/header/index";

export default {
  components: {
    AppHeader,
    SectionTitle,
    Banner
  },
  data() {
    return {
      swiperOpts: {
        direction: 'horizontal',
        speed: 300,
//                    loop: true,
        spaceBetween: 20,
        slidesPerView: 'auto',
//                    centeredSlides: true,
        observer: true,
      },
      swiperOptsSm: {
        direction: 'horizontal',
        speed: 300,
//                    loop: true,
        spaceBetween: 10,
        slidesPerView: 'auto',
        centeredSlides: true,
        observer: true,
      },
      banner: {
        imgUrl: 'services/education/bg.jpg',
        title: this.$i18n.t('education.banner.title'),
        enTitle: ''
      },
      institution: this.$i18n.t('education.institution'),
      team: this.$i18n.t('education.team'),
      wset: this.$i18n.t('education.wset'),
      onlineCourse: this.$i18n.t('education.onlineCourse'),
      course: this.$i18n.t('education.course'),
      list: this.$i18n.t('education.list'),
      activeIndex: 1
    }
  },
  created() {
    this.initData()
  },
  mounted() {
    if (document.getElementById('h1Id')) {
      document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
    }
    let div = document.createElement('h1')
    div.style = "opacity: 0"
    div.id = "h1Id"
    div.innerHTML = "葡萄酒知识传授"
    document.getElementById("bodyId").appendChild(div);
  },
  methods: {
    initData() {
      this.$api.web.getCity({}).then(res => {
        if (res.rows.length > 0) {
          this.team.typeList = res.rows
          this.activeIndex = this.team.typeList[0].id
          this.getExpert()
        }
      })
      this.$api.web.getCourse({}).then(res => {
        if (res.rows.length > 0) {
          this.wset.items = res.rows
        }
      })
      this.$api.web.getOnlineCourse({}).then(res => {
        if (res.rows.length > 0) {
          this.onlineCourse.items = res.rows
        }
      })

    },
    getExpert() {
      this.$api.web.getExpert({cityId: this.activeIndex}).then(res => {
        this.team.items = res.rows
      })
    },
    selectType(item) {
      if (this.activeIndex != item.id) {
        this.activeIndex = item.id
        this.getExpert()
      }
    },
    enter(item) {
      this.$router.push({path: '/services/preview', query: {id: item.id}})
    }
  }
}
</script>
<style lang="scss" scoped>
.img-box {
  position: relative;

  .text-box {
    @include ct();

    .text-title {
      font-size: 30px;
    }

    .text-subTitle {
      font-size: 20px;
    }
  }
}

.inner-box {
  column-count: 2;
}

.text-size {
  font-size: 14px;
  line-height: 40px;
}

.type {
  font-size: 18px;
  color: #9F9F9F;
  border-bottom: 1px solid transparent;
  cursor: pointer;
}

.selected-type {
  color: $primary-color;
  border-bottom: 1px solid $primary-color;
}

.item-title {
  font-size: 18px;
}

.desc {
  font-size: 14px;
  line-height: 23px;
}

.swiper-box {
  .item-swiper {
    width: 390px !important;
  }

  .item-box {
    width: 100%;

    .item-img {
      width: 100%;
    }
  }
}

.course-box {
  .item-box {
    width: 380px;
    margin: 10px;

    .item-content {
      /*column-count: 2;*/
    }
  }
}

.list-box {
  .item-box {
    width: 570px;
    margin: 10px;
  }
}

@media (max-width: $screen-md) {
  .img-box {
    .text-box {
      .text-title {
        font-size: 20px;
      }

      .text-subTitle {
        font-size: 14px;
      }
    }
  }
  .inner-box {
    column-count: 1;
  }
  .item-title {
    font-size: 16px;
  }
  .text-size {
    font-size: 12px;
    line-height: 30px;
  }
  .type {
    font-size: 12px;
  }
  .swiper-box {
    .item-swiper {
      width: 300px !important;
      border-radius: 10px;
      box-shadow: 0px 2px 5px 0px rgba(151, 151, 151, 0.4);
    }

    .item-box {
      .item-img {
        width: 140px;
      }

      .item-title {
        font-size: 20px;
        color: $primary-color;
      }

      .item-line {
        width: 40px;
        height: 2px;
        background-color: $primary-color;
      }

      .desc-box {
        height: 420px;
      }
    }
  }
  .wset-title-box1 {
    display: none !important;
  }
  .wset-title-box {
    position: relative;
    column-count: 3;

    .wset-title {
      width: 100px;
      height: 40px;
      background-color: $primary-color;
      border-radius: 4px;
      font-size: 10px;
      line-height: 14px;
      padding: 6px 18px;
      margin-bottom: 10px;
    }
  }
  .course-box {
    .item-hidden-sm {
      display: none;
    }

    .item-swiper {
      width: 300px !important;
      border-radius: 10px;
      box-shadow: 0px 2px 5px 0px rgba(151, 151, 151, 0.4);
    }

    .item-box {
      width: 100%;
      margin: 0;

      .item-title {
        font-size: 20px;
        color: $primary-color;
      }

      .item-line {
        width: 40px;
        height: 2px;
        background-color: $primary-color;
      }
    }
  }
  .list-box {
    .item-box {
      width: 100%;
      margin: 0;
    }
  }
}

@media (max-width: $screen-sm) {
  .swiper-box {
    .item-swiper {
      width: 240px !important;

      .item-box {
        .item-title {
          font-size: 16px;
        }
      }
    }
  }
  .wset-title-box {
    position: relative;
    column-count: 3;

    .wset-title {
      width: 80px;
      height: 40px;
      font-size: 10px;
      padding: 6px 10px;
    }
  }
  .course-box {
    .item-swiper {
      width: 240px !important;

      .item-box {
        .item-title {
          font-size: 16px;
          color: $primary-color;
        }
      }
    }
  }
}
</style>
